<template>
  <el-card :title="form.id ? '编辑业务' : '新增业务'">
    <el-form
      ref="form"
      v-loading="loading"
      :model="form"
      label-width="150px"
    >
      <b-row>
        <b-col md="6">
          <el-form-item
            label="业务名称"
            prop="name"
            :rules="[ { required: true, message: '业务名称不能为空', trigger: 'change' }]"
          >
            <el-input
              v-model="form.name"
              maxlength="100"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="所属部门"
            prop="deptId"
            :rules="fristInit ? [] : [ { required: true, message: '部门不能为空', trigger: 'change' }]"
          >
            <SelectTree
              v-model="form.deptId"
              :props="treeSelectProps"
              :options="deptOptions"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="状态"
            prop="status"
            :rules="[ { required: true, message: '状态不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.status"
              placeholder="请选择状态"
            >
              <el-option
                v-for="(item,index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="类型"
            prop="productType"
            :rules="[ { required: true, message: '类型不能为空', trigger: 'change' }]"
          >
            <el-select
              v-model="form.productType"
              placeholder="请选择产品类型"
            >
              <el-option
                v-for="(item,index) in [{label:'自研',value:1},{label:'外购',value:2}]"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>

        </b-col>
        <b-col md="6">
          <el-form-item
            label="服务地区"
            prop="serviceArea"
          >
            <el-cascader
              v-model="form.serviceArea"
              placeholder="请选择地区"
              :props="countryProps"
              :show-all-levels="false"
              :options="countryTreeOptions"
              popper-class="cas-class"
              filterable
            />
          </el-form-item>

        </b-col>
        <b-col md="6">
          <el-form-item label="上线时间">
            <el-date-picker
              v-model="form.onlineTime"
              placement="bottom-start"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="业务负责人"
            prop="productManagerId"
            :rules="[ { required: true, message: '业务负责人不能为空', trigger: 'change' }]"
          >
            <div class="flex-start">
              <div class="mr10">
                已选择<font style="color: #0d40b2;font-size: 14px;margin: 0 5px">
                  {{ form.productManagerId ? 1 : 0 }}
                </font>个
              </div>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="personSelectDialogShow1"
              >
                添加人员
              </el-button>
            </div>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item
            label="技术负责人"
            prop="technicalDirectorId"
            :rules="[ { required: true, message: '业务负责人不能为空', trigger: 'change' }]"
          >
            <div class="flex-start">
              <div class="mr10">
                已选择<font style="color: #0d40b2;font-size: 14px;margin: 0 5px">
                  {{ form.technicalDirectorId ? 1 : 0 }}
                </font>个
              </div>
              <el-button
                size="small"
                type="primary"
                icon="el-icon-plus"
                @click="personSelectDialogShow2"
              >
                添加人员
              </el-button>
            </div>
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="描述">
            <el-input
              v-model="form.description"
              v-input-filter
              type="textarea"
              maxlength="100"
            />
          </el-form-item>
        </b-col>
        <b-col md="6">
          <el-form-item label="其他说明">
            <el-input
              v-model="form.otherDescription"
              v-input-filter
              type="textarea"
              placeholder="请输入其他说明"
            />
          </el-form-item>
        </b-col>
        <h3 style="padding-left: 20px">
          个人信息主体权力
        </h3>
        <b-col md="12">
          <el-form-item label="访问权力实现">
            <el-input
                v-model="form.userAccessRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="修改权力实现">
            <el-input
                v-model="form.userUpdateRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="删除权力实现">
            <el-input
                v-model="form.userDeleteRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="获取个人信息副本权力实现">
            <el-input
                v-model="form.userGetRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="限制处理权力实现">
            <el-input
                v-model="form.userLimitHandleRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
        <b-col md="12">
          <el-form-item label="反对处理权力实现">
            <el-input
                v-model="form.userAntiHandleRights"
                type="textarea"
            />
          </el-form-item>
        </b-col>
      </b-row>
    </el-form>
    <div class="dialog-footer text-center">
      <el-button @click="back">
        取 消
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="submitForm"
      >
        提 交
      </el-button>
    </div>
    <PersonSelect
      ref="personSelect1"
      :p-user-id.sync="form.productManagerId"
    />
    <PersonSelect
      ref="personSelect2"
      :p-user-id.sync="form.technicalDirectorId"
    />
  </el-card>
</template>

<script>
import { saveOrUpdateProduct, getProductById } from '@/api/enterprise/product'
import { queryUserOptions } from '@/api/system/system'
import SelectTree from '@core/components/select-tree/SelectTree.vue'
import {
  success, error, treeDataTranslate,
} from '@core/utils/utils'
import { ListDept } from '@/api/system/dept/dept'
import PersonSelect from '@core/components/person-select/PersonSelect.vue'

export default {
  components: { SelectTree, PersonSelect },
  data() {
    return {
      countryProps: {
        emitPath: false,
        checkStrictly: true,
      },
      fristInit: true,
      treeSelectProps: {
        value: 'id', // ID字段名
        label: 'deptName', // 显示名称
        children: 'children', // 子级字段名
      },
      statusOptions: [
        { label: '设计阶段', value: 1 },
        { label: '测试阶段', value: 2 },
        { label: '正式运行', value: 3 },
        { label: '使用阶段', value: 4 },
        { label: '弃用', value: 5 },
      ],
      deptOptions: [],
      users: [],
      loading: false,
      form: {
        name: '',
        deptId: undefined,
        serviceArea: '中国',
        description: '',
        otherDescription: '',
        productManagerId: '',
        userAccessRights: '',
        userDeleteRights: '',
        userUpdateRights: '',
        userGetRights: '',
        userAntiHandleRights: '',
        userLimitHandleRights: '',
        status: 3,
        technicalDirectorId: '',
        id: 0,
        productType: '',
        onlineTime: '',
      },
    }
  },
  created() {
    this.listDept()
    const productId = this.$route.query.id
    if (productId) {
      this.form.id = Number(productId)
      this.getProductById()
    }
    this.getEnterpriseUersSelect()
  },
  mounted() {
    this.navActiveInit(1, '业务管理', '企业管理')
    this.fristInit = false
  },
  destroyed() {
    this.navActiveInit(2, '业务管理', '企业管理', '/product')
  },
  methods: {
    personSelectDialogShow1() {
      this.$refs.personSelect1.dialogVisible = true
    },
    personSelectDialogShow2() {
      this.$refs.personSelect2.dialogVisible = true
    },
    listDept() {
      ListDept().then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.deptOptions = treeDataTranslate(resData.data, 'id', 'parentId')
        }
      })
    },
    back() {
      this.$router.push({
        path: '/product',
      })
    },
    getProductById() {
      getProductById({ id: this.form.id }).then(res => {
        const resData = res.data
        if (resData.code === 0) {
          this.form = resData.data
          if (this.form.onlineTime) {
            this.form.onlineTime = this.form.onlineTime.substr(0, 10)
          }
          if (!this.form.technicalDirectorId) {
            this.form.technicalDirectorId = ''
          }
        }
      })
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          this.form.technicalDirectorId = this.form.technicalDirectorId ? this.form.technicalDirectorId : 0
          saveOrUpdateProduct(this.form).then(res => {
            this.loading = false
            const resData = res.data
            if (resData.code === 0) {
              success(resData.msg)
              this.back()
            } else {
              error(resData.msg)
            }
          })
        }
      })
    },
    getEnterpriseUersSelect() {
      queryUserOptions().then(res => {
        if (res.data.code === 0) {
          this.users = res.data.data
        }
      })
    },
  },
}
</script>
<style lang="scss">
.form-item-flex .el-form-item__content {
  display: flex;
}
</style>
