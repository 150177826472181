<template>
  <el-select
      ref="seSelect"
    :value="valueTitle"
    :disabled="disabled"
    :clearable="clearable"
    @clear="clearHandle"
  >
    <el-option
      :value="valueTitle"
      :label="valueTitle"
    >
      <el-tree
        id="tree-option"
        ref="selectTree"
        :accordion="accordion"
        :data="options"
        :props="props"
        :node-key="props.value"
        :default-expanded-keys="defaultExpandedKey"
        @node-click="handleNodeClick"
      />
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'SelectTree',
  props: {
    /* 配置项 */
    props: {
      type: Object,
      default: () => ({
        value: 'id', // ID字段名
        label: 'title', // 显示名称
        children: 'children', // 子级字段名
      }),
    },
    /* 选项列表数据(树形结构的对象数组) */
    options: {
      type: Array,
      default: () => [],
    },
    /* 初始值 */
    value: {
      type: null,
      default: () => null,
    },
    /* 可清空选项 */
    clearable: {
      type: Boolean,
      default: () => true,
    },
    /* 自动收起 */
    accordion: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      valueId: this.value, // 初始值
      valueTitle: '',
      defaultExpandedKey: [],
    }
  },
  watch: {
    value() {
      this.valueId = this.value
      this.initHandle()
    },
  },
  mounted() {
    this.initHandle()
  },
  methods: {
    // 初始化值
    initHandle() {
      // alert('this.valueId=' + this.valueId)
      const that = this
      // 这里要延迟执行，否则有BUG
      setTimeout(() => {
        if (that.valueId) {
          that.valueTitle = that.$refs.selectTree.getNode(that.valueId).data[that.props.label] // 初始化显示
          that.$refs.selectTree.setCurrentKey(that.valueId) // 设置默认选中
          that.defaultExpandedKey = [that.valueId] // 设置默认展开
        } else {
          that.valueTitle = '' // 初始化显示
          that.$refs.selectTree.setCurrentKey('') // 设置默认选中
        }
      }, 1000)
      this.$nextTick(() => {
        const scrollWrap = document.querySelectorAll('.el-scrollbar .el-select-dropdown__wrap')[0]
        const scrollBar = document.querySelectorAll('.el-scrollbar .el-scrollbar__bar')
        scrollWrap.style.cssText = 'margin: 0px; max-height: none; overflow: hidden;'
        scrollBar.forEach(ele => ele.style.width = 0)
      })
    },
    // 切换选项
    handleNodeClick(node) {
      this.valueTitle = node[this.props.label]
      this.valueId = node[this.props.value]
      this.$emit('input', this.valueId)
      this.defaultExpandedKey = []
      this.$refs.seSelect.blur()
    },
    // 清除选中
    clearHandle() {
      this.valueTitle = ''
      this.valueId = null
      this.defaultExpandedKey = []
      this.clearSelected()
      // this.$emit('getValue',null)
      this.$emit('input', null)
    },
    /* 清空选中样式 */
    clearSelected() {
      const allNode = document.querySelectorAll('#tree-option .el-tree-node')
      allNode.forEach(element => element.classList.remove('is-current'))
    },
  },
}
</script>

<style scoped>
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: auto;
  max-height: 274px;
  padding: 0;
  overflow: hidden;
  overflow-y: auto;
}
.el-select-dropdown__item.selected {
  font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}
.el-tree-node__label {
  font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
  color: #409eff;
  font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
</style>
